// Env
import { environment } from '../environments/environment';
// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';
// Components
import { AppComponent } from './app.component';
import { NotFoundComponent } from './404.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
// Foster Modules
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { CookieConfig, CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { MenuNgrxModule } from '@teamfoster/sdk/menu-ngrx';
// Locale
import localeNl from './nl';
import { registerLocaleData } from '@angular/common';
// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatDialogModule } from '@angular/material/dialog';
import { LanguageInterceptor, LanguageNgrxModule } from '@teamfoster/sdk/language-ngrx';

const cookieConfig: CookieConfig = {
  analyticsCode: 'G-J3X8J789JG',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Accept all',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: false,
  },
  showSettings: true,
};

registerLocaleData(localeNl, 'nl');
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    // Foster Modules
    DictionaryNgrxModule.forRoot({}),
    MatDialogModule,
    MenuNgrxModule.forRoot({}),
    CookieNgrxModule.forRoot(cookieConfig),
    LanguageNgrxModule.forRoot({ defaultLanguage: 'nl-NL' }),
    IconModule.forRoot({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
