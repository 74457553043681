<div class="topbar" [cdkTrapFocus]="menuActive" [class.is--open]="menuActive" [class.set--fixed]="fixed">
  <div class="topbar__bar">
    <div class="container topbar__bar__inner">
      <div class="topbar__bar__actions">
        <button
          class="hamburger is--dark"
          [class.is--active]="menuActive"
          (click)="toggleOverlay.emit()"
          [title]="menuActive ? 'Sluit menu' : 'Open menu'"
        >
          <span class="hamburger__lines"></span>
        </button>
      </div>

      <div class="topbar__bar__title">
        <h2 class="h4 text-uppercase text-light mb-0">{{ 'nav-titel' | fromDictionary }}</h2>
        <span *ngIf="'nav-subtitel' | fromDictionary : false" class="h5 text-condensed text-primary">
          {{ 'nav-subtitel' | fromDictionary }}
        </span>
      </div>

      <a
        [routerLink]="'/'"
        class="topbar__bar__logo"
        (click)="closeOverlay.emit()"
        [attr.aria-label]="'logo-alt-tekst' | fromDictionary"
        [tabIndex]="menuActive ? -1 : 0"
      >
        <img src="/assets/gfx/logo-hanzejaar-rood.svg" [alt]="'logo-alt-tekst' | fromDictionary" />
      </a>
    </div>
  </div>

  <div class="topbar__overlay">
    <div class="container topbar__overlay__inner">
      <div class="row">
        <nav class="scrollbar">
          <h2 class="sr-only">Uitgebreid menu</h2>
          <ul class="nav-list">
            <li class="nav-list__item" *ngFor="let page of menu">
              <a
                class="h5 font-secondary fw-bold"
                (click)="closeOverlay.emit()"
                [routerLink]="page.url"
                [routerLinkActive]="'is--current'"
                [routerLinkActiveOptions]="{ exact: isHomeUrl(page.url) }"
              >
                {{ page.title }}
              </a>

              <ul class="nav-list__item__submenu sub-nav-list">
                <li class="sub-nav-list__item" *ngFor="let subMenu of page.menuItems">
                  <a
                    class="type-body-md"
                    [routerLink]="subMenu.url"
                    (click)="closeOverlay.emit()"
                    [routerLinkActive]="'is--current'"
                    [routerLinkActiveOptions]="{ exact: isHomeUrl(subMenu.url) }"
                  >
                    {{ subMenu?.title }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>
