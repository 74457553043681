import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID,
  InjectionToken,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() loading: boolean = false;
  @Input() menuActive: boolean = false;
  @Input() logoClass: string = '';
  @Input() menu: fromMenu.MenuItem[] = [];

  @Output() toggleOverlay = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openOverlay = new EventEmitter<void>();

  fixed = false;

  constructor(private cd: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>, private router: Router) {
    router.events.subscribe(s => {
      if (s instanceof NavigationStart && this.menuActive) {
        this.closeOverlay.emit();
      }
    });
  }

  ngAfterViewInit(): void {
    this.calculateHeight();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['title'] || changes['subtitle']) {
      this.calculateHeight();
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll')
        .pipe(debounceTime(20))
        .subscribe(a => {
          // show scrollbar after windowinnerheight (window.innerHeight)
          if (window.scrollY > 96) {
            this.fixed = true;
          } else {
            this.fixed = false;
          }
          this.cd.detectChanges();
        });
    }
  }

  calculateHeight() {
    if (isPlatformBrowser(this.platformId)) {
      const navbar = document.querySelector('.topbar__bar');
      const height = navbar?.getBoundingClientRect()?.height;
      document.documentElement.style.setProperty('--nav-height', `${(height || 0) - 10}px`);
    }
  }

  isHomeUrl(url: string | undefined) {
    const homeMatches = ['#', '/', ''];
    return homeMatches.includes(url || '');
  }
}
